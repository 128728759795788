// extracted by mini-css-extract-plugin
export var accordion = "Partner-module--accordion--775dd";
export var buy = "Partner-module--buy--89023";
export var container = "Partner-module--container--84a57";
export var download = "Partner-module--download--1058c";
export var header = "Partner-module--header--9f44d";
export var image = "Partner-module--image--07a83";
export var imageBanner = "Partner-module--imageBanner--9c714";
export var info = "Partner-module--info--a5595";
export var loading = "Partner-module--loading--7074d";
export var open = "Partner-module--open--6d87a";
export var phones = "Partner-module--phones--7aabb";
export var phonesContainer = "Partner-module--phonesContainer--c1455";
export var questions = "Partner-module--questions--915f9";
export var whatsapp = "Partner-module--whatsapp--b9a0e";