import React, { useEffect, useRef, useState } from 'react'
import useWindowSize from '@utils/useWindowSize'
import { Head, Button, Loading } from '@components'
import * as st from '@assets/styl/Partner.module.styl'
import cn from 'classnames'
import requestData from '@api'
import { navigate } from 'gatsby'

interface Props {
  image: string
  lp_name: string
  banner: string
  phones: string
  support_whatsapp: string
  finance_whatsapp: string
  cta: string
  url: string
  url_banner: string
  id: number
}

export default function Partner({ lp_slug }) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Props>({
    banner: '',
    cta: '',
    finance_whatsapp: '',
    id: 0,
    image: '',
    lp_name: '',
    phones: '',
    support_whatsapp: '',
    url: '',
    url_banner: '',
  })

  const vs = useWindowSize()
  const width = vs.width || 0

  const fetchLps = async () => {
    const partners = await requestData('get', `lps/${lp_slug}`)

    if (partners.message === 'OK') {
      setData(partners.result)
      setLoading(false)
    } else {
      navigate('/404')
    }
  }

  useEffect(() => {
    fetchLps()
  }, [])

  const {
    image,
    lp_name,
    banner,
    phones,
    support_whatsapp,
    finance_whatsapp,
    cta,
    url,
    url_banner,
    id,
  } = data

  const canonical = `/ajuda/${lp_slug}/`

  return (
    <div className={cn(st.container, {[st.loading]: loading})}>
      <Head path={canonical} />
      {loading ? (
        <Loading />
      ) : (
        <>
          <section className={st.header}>
            <div className={st.container}>
              <div className={st.image}>
                <img
                  src={image}
                  alt="Imagem editável"
                  width={886}
                  height={743}
                />
              </div>

              <div className={st.info}>
                <h2>Tire suas dúvidas sobre o carregamento na rede WeCharge</h2>
                {width < 750 && (
                  <Button
                    to={`https://api.whatsapp.com/send/?phone=55${support_whatsapp}`}
                    external
                    className={st.whatsapp}
                  >
                    <span className="icon-whatsapp"></span>
                    Fale com nosso suporte
                  </Button>
                )}
                {width < 750 && (
                  <Button
                    to={`https://api.whatsapp.com/send/?phone=55${finance_whatsapp}`}
                    external
                    className={st.whatsapp}
                  >
                    <span className="icon-whatsapp"></span>
                    Fale com nosso suporte
                  </Button>
                )}

                {width >= 750 && <Accordion />}
                <Button
                  to="https://onelink.to/325xfj"
                  external
                  className={st.download}
                  color="white"
                >
                  Baixe o App WeCharge
                </Button>
                {width < 750 && <Accordion />}
                {width < 750 && (
                  <Button
                    to="https://www.wechargeshop.com.br/"
                    external
                    className={st.buy}
                    color="white"
                  >
                    Compre seu carregador
                  </Button>
                )}
                <div className={st.phonesContainer}>
                  <div className={st.phones}>
                    {width >= 750 && (
                      <Button
                        to={`https://whatsa.me/5508002910045/?t=Ol%C3%A1,%20preciso%20de%20ajuda.`}
                        external
                        className={st.whatsapp}
                      >
                        <span className="icon-whatsapp"></span>
                        Fale com nosso suporte
                      </Button>
                    )}
                    {width >= 750 && (
                      <Button
                        to={`https://whatsa.me/5508002910045/?t=Ol%C3%A1,%20preciso%20de%20ajuda.`}
                        external
                        className={st.whatsapp}
                      >
                        <span className="icon-whatsapp"></span>
                        Fale com nosso financeiro
                      </Button>
                    )}
                  </div>

                  <p>
                    {width >= 750 ? 'Ou ligue' : 'Suporte WeCharge'}
                    <strong
                      dangerouslySetInnerHTML={{
                        __html: phones?.replace('\n', '<br/>'),
                      }}
                    ></strong>
                    {width < 750 && 'sac@wecharge.com.br'}
                  </p>
                </div>
                {width >= 750 && (
                  <Button to={url} external className={st.buy} color="white">
                    {cta}
                  </Button>
                )}
              </div>
            </div>
          </section>
          {banner && (
            <div className={st.imageBanner}>
              <a href={url_banner} target="_blank">
                <img
                  src={banner}
                  alt="Imagem Banner Editável"
                  title=""
                  width={1620}
                  height={360}
                />
              </a>
            </div>
          )}
          <section className={st.questions}>
            <div className={st.container}>
              <h3>Principais dúvidas sobre o carregamento</h3>
              <ul>
                <li>
                  <h4>Não consigo tirar o plug do carro, o que eu faço?</h4>
                  <p>
                    Tente travar e destravar o carro e em seguida tente retirar
                    o plugue. Se ainda assim não conseguir, clique no botão
                    acima de "Fale com nosso suporte".
                  </p>
                </li>
                <li>
                  <h4>Como realizar o pagamento das recargas no App?</h4>
                  <p>
                    Como realizar o pagamento das recargas no app? Você pode
                    efetuar pagamentos adicionando um cartão de crédito ou saldo
                    via PIX.
                  </p>
                </li>
                <li>
                  <h4>Já tenho o App EDP, preciso instalar o App WeCharge?</h4>
                  <p>
                    Sim! Recomendamos instalar o App WeCharge pois em breve ele
                    será o único App para usar os eletropostos da EDP.
                  </p>
                </li>
              </ul>
            </div>
          </section>
        </>
      )}
    </div>
  )
}

function Accordion() {
  const [open, setOpen] = useState(false)
  const [height, setHeight] = useState(-1)
  const ref = useRef<HTMLUListElement>(null)

  return (
    <div className={cn(st.accordion, { [st.open]: open })}>
      <h3
        onClick={() => {
          setOpen(!open)
          if (!open) {
            setHeight(ref.current.offsetHeight)
            const interval = setInterval(() => {
              setHeight(0)
              clearInterval(interval)
            }, 200)
          } else {
            setHeight(ref.current.offsetHeight)
            const interval = setInterval(() => {
              setHeight(-1)
              clearInterval(interval)
            }, 20)
          }
        }}
      >
        Como usar o carregador<span></span>
      </h3>
      <div style={height === -1 ? {} : { height: height || 'auto' }}>
        <ul ref={ref}>
          <li>
            <p>Baixe o App WeCharge e cadastre-se.</p>
          </li>
          <li>
            <p>Escaneie o QR Code ou digite o código do carregador.</p>
          </li>
          <li>
            <p>No App, selecione o plug e desbloqueie o carregador.</p>
          </li>
          <li>
            <p>Plugue o carregador no carro e acompanhe a recarga pelo App.</p>
          </li>
          <li>
            <p>
              Finalize a recarga pelo App e{' '}
              <strong>destrave o carro para retirar o plug</strong>.
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}
